const LEAGUE_JUNIOR = 'junior';
const LEAGUE_SENIOR = 'senior';
const LEAGUE_OPEN = 'open';

const ACTIVITY_CLASS_BASS = 'bass';
const ACTIVITY_CLASS_TREBLE = 'treble';
const ACTIVITY_CLASS_BOTH = 'both';

const LEAGUES = [
  {
    id: LEAGUE_JUNIOR,
    label: 'K - Year 4',
    cohorts: ['K', 'y1', 'y2', 'y3', 'y4'],
    nextId: LEAGUE_SENIOR,
    previousId: LEAGUE_OPEN,
  },

  {
    id: LEAGUE_SENIOR,
    label: 'Year 5 - 12',
    cohorts: ['y5', 'y6', 'y7', 'y8', 'y9', 'y10', 'y11', 'y12'],
    nextId: LEAGUE_OPEN,
    previousId: LEAGUE_JUNIOR,
  },

  {
    id: LEAGUE_OPEN,
    label: 'Whole studio',
    cohorts: ['K', 'y1', 'y2', 'y3', 'y4', 'y5', 'y6', 'y7', 'y8', 'y9', 'y10', 'y11', 'y12'],
    nextId: LEAGUE_JUNIOR,
    previousId: LEAGUE_SENIOR,
  }
]

const ACTIVITY_CLASSES = [
  {
    id: ACTIVITY_CLASS_BASS,
    levels: [
      {id: 'L1', label: '1'},
      {id: 'L2', label: '2'},
      {id: 'L3', label: '3'},
      {id: 'L4', label: '4'},
      {id: 'L5', label: '5'},
      {id: 'S1', label: 'Space notes'},
    ],
  },

  {
    id: ACTIVITY_CLASS_TREBLE,
    levels: [
      {id: 'L1', label: '1'},
      {id: 'L2', label: '2'},
      {id: 'L3', label: '3'},
      {id: 'L4', label: '4'},
      {id: 'L5', label: '5'},
      {id: 'S1', label: 'Space notes'},
    ],
  },

  {
    id: ACTIVITY_CLASS_BOTH,
    levels: [
      {id: 'L1', label: '1'},
      {id: 'L2', label: '2'},
      {id: 'L3', label: '3'},
      {id: 'L4', label: '4'},
      {id: 'L5', label: '5'},
      {id: 'S1', label: 'Space notes'},
      {id: 'S2', label: 'Grand challenge'},
    ],
  },
];

module.exports = {
  ACTIVITY_CLASS_BASS,
  ACTIVITY_CLASS_BOTH,
  ACTIVITY_CLASS_TREBLE,
  ACTIVITY_CLASSES,
  LEAGUES,
};
