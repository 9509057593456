import React from "react"
import { graphql } from "gatsby"

import SEO from "@components/seo"
import Noterush from "@components/noterush-leaderboard"
import Layout from "@components/layout"

const transformData = (rawResults, rawClients) => {
  // const {
  //   results: { results: rawResults },
  //   clients: { edges: rawClients },
  // } = data
  const clients = rawClients.map(({ node }) => ({ ...node }))
  const results = rawResults
    .map(({ result }) => ({ ...result }))
    .reduce((memo, result, i) => {
      const client = clients.find(client => client.clientId === result.clientId)
      if (client) {
        return [
          ...memo,
          {
            ...result,
            ...client,
          },
        ]
      }
      return memo
    }, [])

  return results
}

export default ({ data, ...rest }) => {
  const results = transformData(data.results.results, data.clients.edges)
  const rawResults = transformData(data.rawData.results, data.clients.edges)
  return (
    <Layout>
      <SEO
        title="Noterush Leaderboard"
        keywords={[`Piano`, `Teacher`, `Music`, `Lesson`, `Challenges`]}
      />
        <Noterush
          results={results}
          rawResults={rawResults}
          activityClass={rest.pageContext.activityClass}
          league={rest.pageContext.league}
          leagues={rest.pageContext.leagues}
          updatedAt={data.site.buildTime}
        />
    </Layout>
  )
}

export const query = graphql`
  fragment result on googleSheetResultRow {
    clientId
    activityClass
    level
    duration
    timePerNote,
    resultDateTime
  }

  fragment client on googleSheetClientRow {
    clientId
    firstName
    lastName
    cohort
    institution
  }

  query filteredData($activityClassId: String!, $cohorts: [String!]) {
    rawData: allGoogleSheetResultRow {
      results: edges {
        result: node {
          ...result
        }
      }
    }
    results: allGoogleSheetResultRow(
      filter: { activityClass: { eq: $activityClassId } }
    ) {
      scorers: distinct(field: clientId)
      results: edges {
        result: node {
          ...result
        }
      }
    }
    clients: allGoogleSheetClientRow(filter: { cohort: { in: $cohorts } }) {
      edges {
        node {
          ...client
        }
      }
    }
    site {
      buildTime
    }
  }
`
