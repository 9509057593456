import React, { useState } from "react"
import { sortBy, uniqBy, groupBy, mapKeys, mapValues, chain, reduce } from "lodash"
import { Link } from "gatsby"

import {
  ACTIVITY_CLASS_BASS,
  ACTIVITY_CLASS_TREBLE,
  ACTIVITY_CLASS_BOTH,
} from "@constants/noterush-leaderboard"
import ContentPanel from "@components/content-panel"
import "./noterush-leaderboard.css"
import moment from "moment-timezone";

const DURATION_REGEX = /^(([\d]{1,2}):)?([\d]{1,2})?(\.([\d]{1,3}))?$/

const parseDuration = (duration = "") => {
  const match = duration.match(DURATION_REGEX)
  if (match) {
    const [, , min = 0, sec = 0, , milli = 0] = match
    return (Number(min * 60) + Number(sec)) * 1000 + Number(milli)
  }
}

const buildLevels = results => {
  return results.reduce((memo, result) => {
    const { level } = result
    memo[level] = memo[level] || []
    memo[level].push({
      ...result,
      durationMilli: parseDuration(result.duration),
    })
    memo[level] = uniqBy(
      sortBy(memo[level], "durationMilli"),
      'clientId'
    );
    return memo
  }, {})
}

const buildStudentResults = results => {
  return chain(results)
    .groupBy('clientId')
    .mapValues(arrOfResults => groupBy(arrOfResults, 'resultDateTime'))
    // .mapValues(student => mapValues(student, day => day.map(result => result.timePerNote)))
    .mapValues(student => mapValues(student, day => ({
      avgTimePerNote: (
        day.reduce((sum, r) => r.timePerNote + sum, 0) / day.length
      ),
      results: day.map(({activityClass, level, timePerNote}) => ({
        result: `${activityClass}:${level}:${timePerNote}`
      }))
    })))
    .mapValues(student => mapKeys(student, (_, k) => {
      return moment(k, 'D/M');
    }))
    .mapValues(student => reduce(student, (memo, r, k) => [...memo, [k, r.avgTimePerNote]], []))
    .mapValues(avgs => sortBy(avgs, '0'))
    .mapValues(avgs => avgs.map((avg, i) => {
      if (i === 0) {
        return avg
      }
      return [
        ...avg,
        avgs[i-1][1] - avg[1]
      ]
    }))
    .reduce((memo, avgs, clientId) => {
      const studentImprovement = (avgs[0][1] - avgs[avgs.length -1][1]) / avgs[avgs.length - 1][1]
      memo.mostImproved = (
        !memo.mostImproved || memo.mostImproved.delta < studentImprovement ?
          {clientId, delta: studentImprovement} :
          memo.mostImproved
      )
      avgs.forEach(avg => {
        const delta = avg[2]
        const isoWeek = moment(avg[0]).isoWeek()
        if (delta) {
          if (!memo[isoWeek] || memo[isoWeek].delta < delta) {
            memo[isoWeek] = {
              clientId,
              delta
            }
          }
        }
      })
      return memo
    }, {})
    .value()
}

const ResultCell = ({ result }) => (
  <td>
    <div className={`result-name${result ? '' : ' result-name--empty'}`}>
      {result ? `${result.firstName} ${result.lastName.substring(0, 1)}` : "?"}
    </div>
    <div className={`result-time${result ? '' : ' result-time--empty'}`}>{result ? `${result.duration}` : "?"}</div>
  </td>
)

const ResultRow = ({ level, results = [] }) => (
  <tr>
    <td>{level.label}</td>
    <ResultCell result={results[0]} />
    <ResultCell result={results[1]} />
    <ResultCell result={results[2]} />
  </tr>
)

const NoterushLeaderboard = ({ results, rawResults, activityClass, league, leagues, updatedAt }) => {
  const [resultsByLevel] = useState(buildLevels(results))
  const [resultsByStudent] = useState(buildStudentResults(rawResults));

  return (
    <div id="container" className="main__container noterush-leaderboard__container">
      {/* <pre>{JSON.stringify(resultsByStudent, null, 2)}</pre> */}
      <ContentPanel>
        <h1 className="page-heading">Note Rush Leaderboard</h1>

        <div className="classes__container">
          <div className="classes__item__container">
            <Link
              to={`/${league.id}/${ACTIVITY_CLASS_TREBLE}`}
              title="Treble clef challenges"
            >
              <img
                alt="Noterush treble clef"
                className={`clef ${
                  activityClass.id !== ACTIVITY_CLASS_TREBLE
                    ? "inactive"
                    : ""
                } `}
                src="/TrebleClef.png "
              />
            </Link>
          </div>

          <div className="classes__item__container">
            <Link
              to={`/${league.id}/${ACTIVITY_CLASS_BOTH}`}
              title="Both clefs challenges"
            >
              <img
                alt="Noterush both clefs"
                className={`clef ${
                  activityClass.id !== ACTIVITY_CLASS_BOTH ? "inactive" : ""
                }`}
                src="/BothClefs.png "
              />
            </Link>
          </div>

          <div className="classes__item__container">
            <Link
              to={`/${league.id}/${ACTIVITY_CLASS_BASS}`}
              title="Bass clef challenges"
            >
              <img
                alt="Noterush bass cleff"
                className={`clef ${
                  activityClass.id !== ACTIVITY_CLASS_BASS ? "inactive" : ""
                }`}
                src="/BassClef.png "
              />
            </Link>
          </div>
        </div>

        <div className="leagues__container">
          <Link
            className={`league-link${league.id === leagues[0].id ? ' league-link--active' : ''}`}
            to={`/${leagues[0].id}/${activityClass.id}`}
          >
            <span>{leagues[0].label}</span>
          </Link>

          <Link
            className={`league-link${league.id === leagues[1].id ? ' league-link--active' : ''}`}
            to={`/${leagues[1].id}/${activityClass.id}`}
          >
            <span>{leagues[1].label}</span>
          </Link>

          <Link
            className={`league-link${league.id === leagues[2].id ? ' league-link--active' : ''}`}
            to={`/${leagues[2].id}/${activityClass.id}`}
          >
            <span>{leagues[2].label}</span>
          </Link>
        </div>

        <table className="score-table">
          <thead>
            <tr className="place-headings ">
              <th className="align-right level__heading">Level</th>
              <th>1st place</th>
              <th>2nd place</th>
              <th>3rd place</th>
            </tr>
          </thead>

          <tbody>
            {activityClass.levels.map(level => (
              <ResultRow
                key={level.id}
                level={level}
                results={resultsByLevel[level.id]}
              />
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="4" className="footer">
                <span className="updated-at__container">Last updated: {moment.tz(updatedAt, 'Australia/Sydney').format('LLL')}</span>
              </td>
            </tr>
          </tfoot>
        </table>
      </ContentPanel>

    </div>
  )
}

export default NoterushLeaderboard
